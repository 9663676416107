import { graphql } from 'gatsby';
import React from 'react';
import LandingLayout from '~/landing/LandingLayout';

const ContactUsPage: React.FC = () => (
  <LandingLayout>
    <h1>Contact Us</h1>
  </LandingLayout>
);

export default ContactUsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
